/* eslint-disable no-underscore-dangle */
import React, { useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useAuth } from 'hooks/auth';
import { useSearch } from 'hooks/search';
import Axios from 'axios';
import { Form, Modal, Select, Slider, Result } from 'antd';
import { ModalProps } from 'antd/lib/modal';

interface Props extends ModalProps {
  service: 'facebook' | 'instagram' | 'twitter';
  maxImportNumber?: number;
}

const ImportModal: React.FC<Props> = ({
  service,
  maxImportNumber = 60000,
  ...rest
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { user } = useAuth();
  const { queryBuilder, searchedTerms } = useSearch();

  const [success, setSuccess] = useState(false);
  const handleSubmit = useCallback(
    values => {
      const requestObj = {
        master_email: user.email,
        master_id: user._id,
        locale: user.language,
        export_type: 'historical_posts',
        posts_params: JSON.stringify(
          queryBuilder({
            service: [service],
            sort_by: 'top_posts_field',
            customParams: searchedTerms[0],
          }),
        ),
        user_tags: [],
        max_posts: values.quantity || 1,
        brand_name: values.brand,
        brand_searchable: values.brand,
        token:
          'buGP3p565P9n89p40kFnp1mnGgHyNS4b8T1Eofhw2dQ2UI3MIo5M3Q6L45euTEIv',
      };
      return Axios.post(
        `${process.env.REACT_APP_BM_EXPORTER}bm_historical`,
        requestObj,
      ).then(() => setSuccess(true));
    },
    [queryBuilder, searchedTerms, service, user._id, user.email, user.language],
  );

  const formValidation = useCallback(() => {
    form.validateFields().then(values => handleSubmit(values));
  }, [form, handleSubmit]);

  const resetState = () => {
    setSuccess(false)
  }

  if (success) {
    return (
      <Modal
        {...rest}
        title={intl.formatMessage({ id: 'posts.button.import' })}
        afterClose={resetState}
        footer={<></>}
      >
        <Result
          status="success"
          title={intl.formatMessage({ id: 'contact.success.title' })}
        />
      </Modal>
    );
  }

  return (
    <Modal
      title={intl.formatMessage({ id: 'posts.button.import' })}
      onOk={formValidation}
      okText={intl.formatMessage({ id: 'contact.button' })}
      cancelText={intl.formatMessage({ id: 'contact.cancel' })}
      {...rest}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label={intl.formatMessage({ id: 'posts.export.subtitle3' })}
          name="brand"
          rules={[{ required: true }]}
        >
          {user.brands && (
            <Select>
              {user.brands.map(option => (
                <Select.Option
                  value={option.remote_name}
                  key={option.remote_name}
                >
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'posts.export.quantity' })}
          name="quantity"
        >
          <Slider min={1} max={maxImportNumber} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ImportModal;
