import React, { useState, useCallback } from 'react';
import Axios from 'axios';
import { useIntl } from 'react-intl';
import { Form, Modal, Result, Input, Button } from 'antd';
import { ModalProps } from 'antd/lib/modal';

const ActiveCampaign: React.FC<ModalProps> = ({ ...rest }) => {
  const intl = useIntl();
  const [success, setSuccess] = useState(false);

  const handleSubmit = useCallback(values => {
    return Axios.get(
      'https://cors-anywhere.herokuapp.com/http://buzzmonitor.activehosted.com/proc.php',
      {
        params: {
          u: 21,
          f: 21,
          s: null,
          c: 0,
          m: 0,
          act: 'sub',
          v: 2,
          fullname: values.name,
          email: values.email,
          phone: values.phone,
          jsonp: true,
        },
      },
    ).then(() => {
      setSuccess(true);
    });
  }, []);

  const resetState = () => {
    setSuccess(false)
  }
  
  if (success) {
    return (
      <Modal
        {...rest}
        title={intl.formatMessage({ id: 'contact.title.import' })}
        afterClose={resetState}
        footer={<></>}
      >
        <Result
          status="success"
          title={intl.formatMessage({ id: 'contact.success.title' })}
          subTitle={intl.formatMessage({ id: 'contact.success.subtitle' })}
          extra={[
            <a href="mailto:comercial@getbuzzmonitor.com.br">
              comercial@getbuzzmonitor.com.br
            </a>,
          ]}
        />
      </Modal>
    );
  }

  return (
    <Modal
      {...rest}
      title={intl.formatMessage({ id: 'activecampaign.moreposts' })}
      footer={<></>}
    >
      <p>{intl.formatMessage({ id: 'contact.subtitle1' })}</p>
      <p>{intl.formatMessage({ id: 'contact.subtitle2' })}</p>
      <Form
        onFinish={handleSubmit}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label={intl.formatMessage({ id: 'contact.name' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'contact.error.name' }),
            },
          ]}
          name="name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'contact.phone' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'contact.error.phone' }),
            },
          ]}
          name="phone"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'contact.email' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'contact.error.emailempty' }),
            },
          ]}
          name="email"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={intl.formatMessage({ id: 'contact.company' })}
          rules={[
            {
              required: true,
              message: intl.formatMessage({ id: 'contact.error.company' }),
            },
          ]}
          name="company"
        >
          <Input />
        </Form.Item>
        <Button type="primary" htmlType="submit" block>
          {intl.formatMessage({ id: 'contact.button' })}
        </Button>
      </Form>
    </Modal>
  );
};

export default ActiveCampaign;
